<template>
    <!-- 合作伙伴 -->
    <div class="partner">
        <!-- 内容一 -->
        <div class="ptBox1">
            <img src="http://iv.okvu.cn/vugw/img/1568097952110888.jpg" alt="" class="pb1Bg" />
            <div class="pb1P">携手威有</div>
            <div class="pb1P">共享智能家</div>
        </div>
        <!-- tab栏 -->
        <div class="ptTabs">
            <ul>
                <li class="ptLi"><a href="#pb2">商学院</a></li>
                <li class="ptLi"><a href="#pb3">品牌概述</a></li>
                <li class="ptLi"><a href="#pb4">终端形象</a></li>
                <li class="ptLi"><a href="#pb5">加盟支持</a></li>
                <li class="ptLi"><a href="#pb6">申请加盟</a></li>
            </ul>
        </div>
        <!-- 内容二 -->
        <div class="ptBox2" id="pb2">
            <img src="http://iv.okvu.cn/vugw/img/1568162370104235.jpg" alt="" class="pb2Bg" />
            <div class="pb2Text">
                <img src="http://iv.okvu.cn/vugw/img/1568162737768160.png" alt="" class="p2tBg" />
                <p class="p2tP1">做品牌</p>
                <p class="p2tP1">选择比努力更重要</p>
                <p class="p2tP2">CHOICE IS</p>
                <p class="p2tP2">MORE IMPORYANT</p>
            </div>
            <div class="pb2Text2">
                <img src="http://iv.okvu.cn/vugw/img/1568162384128966.png" alt="" class="pt2Img" />
            </div>
            <div class="pb2Mask">
                <div class="pmBox">
                    国家高新技术企业、浙江省科技型企业、嘉兴学院产学研基地，产品全面通过国家3C认证、
                    安全生产标准化三级企业、浙江省放心消费产品、49项国家专利技术、ISO9001质量管理体系认证、
                    中国建筑装饰行业推广品牌、中国天花吊顶常务理事单位、中国吊顶行业优秀企业、威有家居环保健康品牌、
                    中国建筑装饰装修材料协会产品创新奖、中国集成吊顶行业十大新品、十年荣膺“中国集成吊顶行业十大品牌”
                </div>
            </div>
        </div>
        <!-- 内容三 -->
        <div class="ptBox3" id="pb3">
            <div class="pb3Box">
                <div class="p3bTitle">
                    <div class="p3tBox">
                        <p class="ptP1">SCHOOL OF</p>
                        <p class="ptP1">BUSINESS</p>
                        <p class="ptP2">商学院</p>
                    </div>
                </div>
                <div class="p3bContent">
                    <div class="pbcTop">
                        <p class="ptP1">威有人才战略工程正式启动</p>
                        <p class="ptP1">
                            2015年，威有在终调监销核心竞争力方面进行全面突被，以培训营销人才为基点，以服务全球客户为目的.4月16日，“欧斯
                            <br />
                            迪商字院”正式成立，标志着威有人才战略工程正式启动，也标志着威有在人才队伍培训开发上取得重大突破，逐渐形成以
                            <br />
                            人才发展推动品牌核心常争力的战略格局。
                        </p>
                    </div>
                    <div class="pbcList">
                        <ul>
                            <li class="pbcLi" v-for="item in 4" :key="item">
                                <div class="plSmall">
                                    <div class="pcl1">NO.{{ item }}</div>
                                    <div class="pcl2">
                                        <img
                                            src="http://iv.okvu.cn/vugw/img/20200226-1582698277597403.png"
                                            alt=""
                                            class="p2Img"
                                        />
                                    </div>
                                    <div class="pcl3">
                                        2012年11月13--14日，威有商学院将召开武林俱乐部第二期特训营。
                                        本期商学院特别邀请经销商培训专家、外企营销管理经验.....
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容四 -->
        <div class="ptBox4" id="pb4">
            <div class="pb4Box">
                <div class="p4bTitle">
                    <div class="p4tBox">
                        <p class="ptP1">BRAND</p>
                        <p class="ptP1">OVERVIEW</p>
                        <p class="ptP2">品牌概述</p>
                    </div>
                </div>
                <div class="p4bList">
                    <img src="http://iv.okvu.cn/vugw/img/1568878239267671.jpg" alt="" class="p4lImg" />
                    <ul>
                        <li class="p4Li" v-for="item in 5" :key="item">
                            <div class="p4Text">
                                <p class="p4t1">10年专注<br />中国高端集成吊顶专家！<br />+</p>
                            </div>
                            <div class="p4Mask">
                                <div class="p4m1">10年专注<br />中国高端集成吊顶专家！</div>
                                <div class="p4m2">
                                    威有，专注吊顶十余年；<br />CCTV央视强力猪腿品牌；<br />
                                    连续八年荣誉中国集成吊顶十大品牌；<br />以品质承载梦想，以诚信打造品牌；<br />
                                    为中国千千万万家庭提供舒适居家生活！
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 内容五 -->
        <div class="ptBox5" id="pb5">
            <div class="pb5Box">
                <div class="p5bTitle">
                    <div class="p5tBox">
                        <p class="ptP1">TERMINAL</p>
                        <p class="ptP1">IMAGE</p>
                        <p class="ptP2">终端形象</p>
                    </div>
                </div>
                <div class="p5bImgs">
                    <div class="p5IBox">
                        <div class="p5Boxs"></div>
                        <img src="http://iv.okvu.cn/vugw/img/1599036604778967.jpg" alt="" class="p5Img" />
                        <img src="http://iv.okvu.cn/vugw/img/1568099043893425.png" alt="" class="p5Icon" />
                        <p class="p5P">威有专卖店</p>
                    </div>
                    <div class="p5IBox">
                        <div class="p5Boxs"></div>
                        <img src="http://iv.okvu.cn/vugw/img/1568100039339778.jpg" alt="" class="p5Img" />
                        <img src="http://iv.okvu.cn/vugw/img/1568099044138975.png" alt="" class="p5Icon" />
                        <p class="p5P">威有智能厨卫电器店</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容六 -->
        <div class="ptBox6" id="pb6">
            <div class="pb6Box">
                <div class="p6bTitle">
                    <div class="p6tBox">
                        <p class="ptP1">JOIN</p>
                        <p class="ptP1">SUPPRT</p>
                        <p class="ptP2">加盟支持</p>
                    </div>
                </div>
                <div class="p6Content">
                    <div class="p6cTab">
                        <ul>
                            <li class="p6tLi" @mousemove="showP6(1)">全面的终端培训系统</li>
                            <li class="p6tLi" @mousemove="showP6(2)">管家式的扶持体系</li>
                            <li class="p6tLi" @mousemove="showP6(3)">全程无忧的持续运营体系</li>
                            <li class="p6tLi" @mousemove="showP6(4)">全程服务心贴心客户使用有保证</li>
                        </ul>
                    </div>
                    <div class="p6cBox" v-show="showP6B === 1">
                        <div class="pbLeft">
                            <img src="http://iv.okvu.cn/vugw/img/1568083940131522.jpg" alt="" class="plImg" />
                        </div>
                        <div class="pbRight">
                            <div class="prBox">
                                <div class="pbTop">全面的终端培训系统</div>
                                <div class="pbText">
                                    威有携手和君智业强力打造终端营销系统，从设计案例营销、微信营销、样板房营销、
                                    异业联盟营销、全屋整装营销、标准化售后管理、团队运营管理等
                                    方面强势打造终端市场，创造和引导客户需求，大力进行导购培训、
                                    店长培训、设计师培训、销售精英培训、经销商经营运作培训，以提高加盟商综合实力。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p6cBox" v-show="showP6B === 2">
                        <div class="pbLeft">
                            <img src="http://iv.okvu.cn/vugw/img/1570587374507914.jpg" alt="" class="plImg" />
                        </div>
                        <div class="pbRight">
                            <div class="prBox">
                                <div class="pbTop">全面的终端培训系统</div>
                                <div class="pbText">
                                    威有携手和君智业强力打造终端营销系统，从设计案例营销、微信营销、样板房营销、
                                    异业联盟营销、全屋整装营销、标准化售后管理、团队运营管理等
                                    方面强势打造终端市场，创造和引导客户需求，大力进行导购培训、
                                    店长培训、设计师培训、销售精英培训、经销商经营运作培训，以提高加盟商综合实力。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p6cBox" v-show="showP6B === 3">
                        <div class="pbLeft">
                            <img src="http://iv.okvu.cn/vugw/img/1570587374709024.jpg" alt="" class="plImg" />
                        </div>
                        <div class="pbRight">
                            <div class="prBox">
                                <div class="pbTop">全面的终端培训系统</div>
                                <div class="pbText">
                                    威有携手和君智业强力打造终端营销系统，从设计案例营销、微信营销、样板房营销、
                                    异业联盟营销、全屋整装营销、标准化售后管理、团队运营管理等
                                    方面强势打造终端市场，创造和引导客户需求，大力进行导购培训、
                                    店长培训、设计师培训、销售精英培训、经销商经营运作培训，以提高加盟商综合实力。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p6cBox" v-show="showP6B === 4">
                        <div class="pbLeft">
                            <img src="http://iv.okvu.cn/vugw/img/1570587374913125.jpg" alt="" class="plImg" />
                        </div>
                        <div class="pbRight">
                            <div class="prBox">
                                <div class="pbTop">全面的终端培训系统</div>
                                <div class="pbText">
                                    威有携手和君智业强力打造终端营销系统，从设计案例营销、微信营销、样板房营销、
                                    异业联盟营销、全屋整装营销、标准化售后管理、团队运营管理等
                                    方面强势打造终端市场，创造和引导客户需求，大力进行导购培训、
                                    店长培训、设计师培训、销售精英培训、经销商经营运作培训，以提高加盟商综合实力。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容七 -->
        <div class="ptBox7" id="pb7">
            <div class="pb7Top">
                <p class="p7tP">合作共赢 共创未来</p>
                <p class="p7tP">加盟热线：{{ getWebData().phone }}</p>
            </div>
            <div class="pb7Box">
                <div class="p7bTitle">
                    <div class="p7tBox">
                        <p class="ptP1">APPLY</p>
                        <p class="ptP1">FOR-JOINING</p>
                        <p class="ptP2">申请加盟</p>
                    </div>
                </div>
                <form class="p7bF">
                    <div class="pfInput">
                        <div class="pfName">姓名：</div>
                        <input type="text" v-model.trim="form.name" />
                    </div>
                    <div class="pfInput">
                        <div class="pfName">公司：</div>
                        <input type="text" v-model.trim="form.company" />
                    </div>
                    <div class="pfInput">
                        <div class="pfName">电话：</div>
                        <input type="text" v-model.trim="form.phone" />
                    </div>
                    <div class="pfInput">
                        <div class="pfName">邮箱：</div>
                        <input type="text" v-model.trim="form.email" />
                    </div>
                    <div class="btns">
                        <button @click.prevent="subForm">提交</button>
                        <button
                            class="clear"
                            @click="clear = false"
                            :disabled="!clear"
                            :style="clear ? '' : { cursor: 'not-allowed', backgroundColor: '#ebeef5', color: '#c0c4cc' }"
                        >
                            清除
                        </button>
                        <!-- <input class="yzm" type="text" /> -->
                        <!-- <img src="https://www.ousidi.com/gmcaptcha/3748800494" alt="" class="yzmImg" /> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    name: "Partner",
    inject: ["getWebData"],
    data() {
        return {
            showP6B: 1,
            form: {
                name: "",
                company: "",
                phone: "",
                email: "",
            },
        };
    },
    methods: {
        showP6(i) {
            this.showP6B = i;
        },
        async subForm() {
            let { form } = this;
            if (iskong(form.name) || iskong(form.phone) || iskong(form.company) || iskong(form.email)) {
                return this.$message.error("请勿为空！");
            }
            let { data } = await axios.post(
                "/api/mail/add",
                QueryString.stringify({
                    ...form,
                    channel: "申请合作",
                })
            );

            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.form = {
                    name: "",
                    company: "",
                    phone: "",
                    email: "",
                };
            }

            function iskong(data = "") {
                // 为空
                if (!data.replaceAll(" ", "")) return true;
                return false;
            }
        },
    },
    computed: {
        clear: {
            get() {
                let s = false;
                for (const k in this.form) {
                    if (this.form[k]) s = true;
                }
                // s=true 可以清除
                // s=false 不需要清除
                return s;
            },
            set() {
                this.form = {
                    name: "",
                    company: "",
                    phone: "",
                    email: "",
                };
            },
        },
    },
};
</script>

<style lang="less" scoped>
.partner {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    // 内容一
    .ptBox1 {
        width: 100%;
        height: 460px;
        position: relative;
        .pb1Bg {
            width: 100%;
            height: 100%;
        }
        .pb1P {
            width: 353px;
            height: 66px;
            line-height: 66px;
            font-size: 50px;
            color: white;
            position: absolute;
            top: 30%;
            right: 10%;
        }
        .pb1P:nth-child(3) {
            position: absolute;
            top: 50%;
            right: 0%;
        }
    }
    // tab栏
    .ptTabs {
        width: 100%;
        height: 70px;
        ul {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            .ptLi {
                width: 19%;
                height: 20px;
                margin-top: 25px;
                text-align: center;
                border-left: 1px solid #bebebe;
                a {
                    display: block;
                    width: 50%;
                    height: 35px;
                    line-height: 35px;
                    margin: 0 auto;
                    margin-top: -10px;
                    font-size: 14px;
                }
                a:hover {
                    background-color: #7781f1;
                    border-radius: 10px;
                    color: white;
                }
            }
            .ptLi:nth-child(5) {
                border-right: 1px solid #bebebe;
            }
        }
    }
    // 内容二
    .ptBox2 {
        width: 100%;
        height: 800px;
        position: relative;
        .pb2Bg {
            width: 100%;
            height: 100%;
        }
        .pb2Text {
            width: 500px;
            height: 300px;
            position: absolute;
            top: 2%;
            left: 0;
            .p2tBg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: -30%;
            }
            .p2tP1 {
                width: 400px;
                height: 70px;
                line-height: 70px;
                font-size: 50px;
                color: #464646;
                position: absolute;
                top: 3%;
                left: 20%;
            }
            .p2tP1:nth-child(2) {
                position: absolute;
                top: 30%;
                left: 20%;
            }
            .p2tP2 {
                width: 400px;
                height: 40px;
                line-height: 40px;
                font-size: 30px;
                color: #7781f1;
                position: absolute;
                top: 60%;
                left: 20%;
            }
            .p2tP2:nth-child(4) {
                position: absolute;
                top: 80%;
                left: 20%;
            }
        }
        .pb2Text2 {
            width: 600px;
            height: 150px;
            position: absolute;
            top: 40%;
            right: 5%;
            .pt2Img {
                width: 100%;
                height: 100%;
            }
        }
        .pb2Mask {
            width: 100%;
            height: 287px;
            background: rgba(0, 0, 0, 0.4);
            position: absolute;
            bottom: 0;
            .pmBox {
                width: 860px;
                height: 115px;
                color: white;
                position: absolute;
                bottom: 30%;
                right: 10%;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    // 内容三
    .ptBox3 {
        width: 100%;
        height: 1000px;
        .pb3Box {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding-top: 3%;
            box-sizing: border-box;
            .p3bTitle {
                width: 100%;
                height: 100px;
                .p3tBox {
                    width: 140px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    .ptP1 {
                        width: 140px;
                        height: 29px;
                        line-height: 29px;
                        margin: 0 auto;
                        font-size: 23px;
                        color: #7781f1;
                        text-align: center;
                    }
                    .ptP1:nth-child(2) {
                        color: black;
                    }
                    .ptP2 {
                        width: 140px;
                        height: 37px;
                        line-height: 37px;
                        font-size: 25px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
            .p3tBox::before {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-right: none;
                left: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p3tBox::after {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-left: none;
                right: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p3bContent {
                width: 100%;
                height: 820px;
                margin-top: 50px;
                background-color: #fafafa;
                padding-top: 40px;
                .pbcTop {
                    width: 70%;
                    height: 120px;
                    margin: 0 auto;
                    text-align: center;
                    .ptP1 {
                        width: 100%;
                        height: 35px;
                        line-height: 35px;
                        font-size: 30px;
                    }
                    .ptP1:nth-child(2) {
                        width: 100%;
                        height: 85px;
                        margin-top: 10px;
                        font-size: 16px;
                        line-height: 25px;
                        color: #666;
                    }
                }
                .pbcList {
                    width: 100%;
                    height: 500px;
                    margin-top: 80px;
                    ul {
                        // width: 1280px;
                        width: 100%;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-around;
                        .pbcLi {
                            width: 22%;
                            height: 446px;
                            .plSmall {
                                width: 100%;
                                height: 240px;
                                margin-top: 138px;
                                background-color: #eee;
                                position: relative;
                                border-radius: 10px;
                                transition: 0.3s;
                                box-shadow: 5px 5px 5px 0 #cfcfcf;
                                .pcl1 {
                                    width: 124px;
                                    height: 124px;
                                    line-height: 124px;
                                    border-radius: 50%;
                                    color: #141414;
                                    background: #dcdcdc;
                                    font-size: 33px;
                                    text-align: center;
                                    position: absolute;
                                    top: -62px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                                .pcl2 {
                                    // width: 235px;
                                    width: 80%;
                                    // height: 156px;
                                    display: none;
                                    margin: 0 auto;
                                    position: absolute;
                                    top: 20%;
                                    left: 10%;
                                    .p2Img {
                                        width: 100%;
                                        // height: 100%;
                                        border-radius: 5px;
                                        max-height: 222px;
                                    }
                                }
                                .pcl3 {
                                    width: 80%;
                                    height: 120px;
                                    box-sizing: border-box;
                                    color: #666;
                                    position: absolute;
                                    bottom: 10%;
                                    left: 10%;
                                }
                            }
                            .plSmall:hover {
                                // width: 300px;
                                height: 520px;
                                box-shadow: none;
                                background: #7781f1;
                                transform: translateY(-140px);
                                .pcl1 {
                                    // transform: translateY(50px);
                                    // transition: 0.2s;
                                }
                                .pcl2 {
                                    display: block;
                                }
                                .pcl3 {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 内容四
    .ptBox4 {
        width: 100%;
        height: 950px;
        margin-top: 50px;
        .pb4Box {
            // width: 1210px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            .p4bTitle {
                width: 100%;
                height: 100px;
                .p4tBox {
                    width: 140px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    .ptP1 {
                        width: 140px;
                        height: 29px;
                        line-height: 29px;
                        margin: 0 auto;
                        font-size: 23px;
                        color: #7781f1;
                        text-align: center;
                    }
                    .ptP1:nth-child(2) {
                        color: black;
                    }
                    .ptP2 {
                        width: 140px;
                        height: 37px;
                        line-height: 37px;
                        font-size: 25px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
            .p4tBox::before {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-right: none;
                left: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p4tBox::after {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-left: none;
                right: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p4bList {
                width: 100%;
                height: 705px;
                margin-top: 50px;
                position: relative;
                overflow: hidden;
                .p4lImg {
                    width: 100%;
                    // height: 100%;
                    min-height: 100%;
                    position: absolute;
                }
                ul {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: flex;
                    flex-wrap: wrap;
                    .p4Li {
                        width: 402px;
                        height: 352px;
                        color: white;
                        position: relative;
                        .p4Text {
                            width: 270px;
                            height: 80px;
                            margin: 0 auto;
                            margin-top: 150px;
                            .p4t1 {
                                width: 100%;
                                height: 50px;
                                line-height: 25px;
                                font-size: 18px;
                                text-align: center;
                            }
                        }
                        .p4Mask {
                            width: 90%;
                            height: 90%;
                            background: #7781f180;
                            position: absolute;
                            top: 5%;
                            left: 5%;
                            opacity: 0;
                            border-radius: 10px;
                            transition: 0.5s;
                            .p4m1 {
                                width: 80%;
                                height: 60px;
                                font-size: 18px;
                                line-height: 30px;
                                margin: 0 auto;
                                margin-top: 80px;
                            }
                            .p4m2 {
                                width: 80%;
                                height: 100px;
                                line-height: 25px;
                                margin: 0 auto;
                                margin-top: 60px;
                                font-size: 14px;
                            }
                        }
                    }
                    .p4Li:hover {
                        .p4Text {
                            opacity: 0;
                        }
                        .p4Mask {
                            opacity: 1;
                            .p4m1,
                            .p4m2 {
                                transition: 0.5s;
                                transform: translateY(-30px);
                            }
                        }
                    }
                    .p4Li:nth-child(4),
                    .p4Li:nth-child(5) {
                        width: 600px;
                        height: 352px;
                        width: 50%;
                    }
                    .p4Li:nth-child(1),
                    .p4Li:nth-child(2) {
                        border-bottom: 1px solid white;
                        border-right: 1px solid white;
                        width: 33.3%;
                    }
                    .p4Li:nth-child(3) {
                        border-bottom: 1px solid white;
                        width: 33.3%;
                    }
                    .p4Li:nth-child(4) {
                        border-right: 1px solid white;
                    }
                }
            }
        }
    }
    // 内容五
    .ptBox5 {
        width: 100%;
        height: 860px;
        padding-top: 100px;
        background: url("http://iv.okvu.cn/vugw/img/1568083674671295.jpg") no-repeat center;
        background-size: 100%;
        .pb5Box {
            width: 95%;
            height: 100%;
            margin: 0 auto;
            .p5bTitle {
                width: 100%;
                height: 100px;
                .p5tBox {
                    width: 140px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    .ptP1 {
                        width: 140px;
                        height: 29px;
                        line-height: 29px;
                        margin: 0 auto;
                        font-size: 23px;
                        color: #7781f1;
                        text-align: center;
                    }
                    .ptP1:nth-child(2) {
                        color: black;
                    }
                    .ptP2 {
                        width: 140px;
                        height: 37px;
                        line-height: 37px;
                        font-size: 25px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
            .p5tBox::before {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-right: none;
                left: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p5tBox::after {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-left: none;
                right: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p5bImgs {
                width: 100%;
                height: 474px;
                margin-top: 50px;
                display: flex;
                justify-content: space-between;
                .p5IBox {
                    width: 49%;
                    height: 100%;
                    // float: left;
                    // margin-left: 10px;
                    position: relative;
                    overflow: hidden;
                    .p5Boxs {
                        width: 90%;
                        height: 90%;
                        border: 1px solid white;
                        position: absolute;
                        top: 5%;
                        left: 5%;
                        z-index: 7;
                    }
                    .p5Img {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                    }
                    .p5Icon {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: 40%;
                        left: calc(50% - 50px);
                        z-index: 7;
                        transition: 0.5s;
                    }
                    .p5P {
                        width: 100%;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        font-size: 28px;
                        color: #fafafa;
                        position: absolute;
                        top: 60%;
                        opacity: 0;
                        transition: 0.5s;
                    }
                    .p5P:nth-child(2) {
                        position: absolute;
                        left: 25%;
                    }
                }
                .p5IBox:nth-child(2) {
                    // margin-left: 30px;
                }
                .p5IBox:hover {
                    .p5Img {
                        transform: scale(1.1);
                    }
                    .p5Icon {
                        transform: translateY(-20px);
                    }
                    .p5P {
                        opacity: 1;
                    }
                }
            }
        }
    }
    // 内容六
    .ptBox6 {
        width: 100%;
        height: 680px;
        margin-top: 45px;
        .pb6Box {
            // width: 1280px;
            height: 100%;
            margin: 0 auto;
            overflow-x: hidden;
            .p6bTitle {
                width: 100%;
                height: 100px;
                .p6tBox {
                    width: 140px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    .ptP1 {
                        width: 140px;
                        height: 29px;
                        line-height: 29px;
                        margin: 0 auto;
                        font-size: 23px;
                        color: #7781f1;
                        text-align: center;
                    }
                    .ptP1:nth-child(2) {
                        color: black;
                    }
                    .ptP2 {
                        width: 140px;
                        height: 37px;
                        line-height: 37px;
                        font-size: 25px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
            .p6tBox::before {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-right: none;
                left: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p6tBox::after {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-left: none;
                right: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p6Content {
                width: 100%;
                height: 500px;
                margin-top: 50px;
                .p6cTab {
                    width: 100%;
                    height: 75px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        text-align: center;
                        border-bottom: 1px solid gainsboro;
                        .p6tLi {
                            width: 24%;
                            height: 25px;
                            line-height: 25px;
                            font-size: 18px;
                            border-left: 1px solid #666;
                            margin-top: 25px;
                            cursor: pointer;
                        }
                        .p6tLi:nth-child(1) {
                            border: none;
                        }
                        .p6tLi:hover {
                            color: #7781f1;
                        }
                    }
                }
                .p6cBox {
                    width: 100%;
                    height: 362px;
                    margin-top: 50px;
                    transition: 0.5s;
                    .pbLeft {
                        width: 50%;
                        height: 100%;
                        float: left;
                        animation-name: slideRight;
                        animation-duration: 1s;
                        animation-timing-function: ease-in-out;
                        .plImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .pbRight {
                        width: 50%;
                        height: 100%;
                        float: left;
                        .prBox {
                            width: 453px;
                            height: 200px;
                            margin: 0 auto;
                            margin-top: 80px;
                            animation-name: slideLeft;
                            animation-duration: 1s;
                            animation-timing-function: ease-in-out;
                            .pbTop {
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                font-size: 24px;
                                border-bottom: 1px solid gainsboro;
                            }
                            .pbText {
                                width: 100%;
                                height: 130px;
                                margin-top: 20px;
                                font-size: 15px;
                                color: #666;
                            }
                        }
                    }
                }
            }
        }
        @keyframes slideRight {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
            0% {
                transform: translateX(-150%);
            }
            50% {
                transform: translateX(8%);
            }
            65% {
                transform: translateX(-4%);
            }
            80% {
                transform: translateX(4%);
            }
            95% {
                transform: translateX(-2%);
            }
            100% {
                transform: translateX(0);
            }
        }
        @keyframes slideLeft {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
            0% {
                transform: translateX(150%);
            }
            50% {
                transform: translateX(-8%);
            }
            65% {
                transform: translateX(4%);
            }
            80% {
                transform: translateX(-4%);
            }
            95% {
                transform: translateX(2%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }
    // 内容七
    .ptBox7 {
        width: 100%;
        // height: 1000px;
        .pb7Top {
            width: 100%;
            height: 369px;
            background: url("http://iv.okvu.cn/vugw/img/1568083666655890.jpg") center bottom no-repeat;
            position: relative;
            .p7tP {
                width: 472px;
                height: 72px;
                line-height: 72px;
                font-size: 55px;
                position: absolute;
                top: 10%;
                left: 35%;
            }
            .p7tP:nth-child(2) {
                width: 400px;
                height: 40px;
                line-height: 40px;
                font-size: 30px;
                color: #7781f1;
                position: absolute;
                top: 35%;
                left: 40%;
            }
        }
        .pb7Box {
            width: 1070px;
            height: 350px;
            margin: 0 auto;
            margin-top: 50px;
            .p7bTitle {
                width: 100%;
                height: 100px;
                .p7tBox {
                    width: 180px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    .ptP1 {
                        width: 180px;
                        height: 29px;
                        line-height: 29px;
                        margin: 0 auto;
                        font-size: 23px;
                        color: #7781f1;
                        text-align: center;
                    }
                    .ptP1:nth-child(2) {
                        color: black;
                    }
                    .ptP2 {
                        width: 140px;
                        height: 37px;
                        line-height: 37px;
                        font-size: 25px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
            .p7tBox::before {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-right: none;
                left: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p7tBox::after {
                content: "";
                display: block;
                position: absolute;
                width: 70px;
                height: 80%;
                border: 3px solid #8c8c8c;
                border-left: none;
                right: -90px;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p7bF {
                width: 100%;
                // height: 275px;
                margin-top: 50px;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                align-content: flex-start;
                .pfInput {
                    width: 530px;
                    height: 46px;
                    line-height: 46px;
                    margin-bottom: 30px;
                    .pfName {
                        width: 100px;
                        height: 46px;
                        text-align: center;
                        float: left;
                    }
                    input {
                        width: 400px;
                        height: 40px;
                        border-radius: 10px;
                        background-color: #eee;
                        float: left;
                        padding-left: 20px;
                    }
                }
                .btns {
                    position: absolute;
                    width: 200px;
                    right: 40px;
                    bottom: -30px;
                    display: flex;
                    justify-content: space-between;
                    & > button {
                        // flex: 1;
                        width: 45%;
                        border-radius: 10px;
                        border: none;
                        height: 36px;
                        line-height: 36px;
                        text-align: center;
                        color: white;
                        background-color: #7781f1;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
